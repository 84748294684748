/* eslint-disable */
import * as yup from "yup";
import {
  addBankObj,
  changePasswordFields,
  corporateFieldNameStep1,
  corporateFieldNameStep2,
  forgotPasswordFields,
  retailFieldName,
  retailFieldNameStep2,
} from "../staticObjects";
import { captcha, email, otp, otpCode, password } from "./untils";

const { email: forgotEmail, captchaResponse, code } = forgotPasswordFields;
const { confirmpass, existing, changepass, otpField } = changePasswordFields;
const { chooseAccount, accountNo, accountName } = addBankObj;

export const confirmPass = yup
  .string()
  .required("Please enter confirm password")
  .oneOf([yup.ref("changepass"), null], "Confirm password should be same");

export const forgotPassSchemas = yup.object().shape({
  [forgotEmail]: email,
  // [code]: otpCode,
  // [captchaResponse]: captcha,
});
export const forgotPasswithoutemailSchemas = yup.object().shape({
  // [forgotEmail]: email,
  // [code]: otpCode,
  // [captchaResponse]: captcha,
});

export const createPassSchemas = yup.object().shape({
  [changepass]: password(true),
  [confirmpass]: confirmPass,
});

export const changePassSchemas = yup.object().shape({
  [existing]: yup.string().required("Please enter old password"),
  [changepass]: password(true),
  [confirmpass]: confirmPass,
  [otpField]: otp,
});

const {
  piNationality,
  piFirstName,
  piLastName,
  piDob,
  piGender,
  piIdNumber,
  piCivilNumber,
  piCivilNumber1,
  piCivilNumber2,
  piCivilNumber3,
  prCountry,
  prProvinceOrCity,
  prSumOrDistrict,
  prBagOrKhoroo,
  prStreet,
  // prRegion,
  prApartment,
  prAddressDetails,
  isAddressSame,
  craCountry,
  craProvinceOrCity,
  craSumOrDistrict,
  craBagOrKhoroo,
  craStreet,
  craRegion,
  craApartment,
  craAddressDetails,
} = retailFieldName;

const onlyValidated = (txt, maxLen = 50) => {
  return (
    yup
      .string()
      .required(txt)
      // .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, "Only alphabets and numbers are allowed with single space")
      .max(maxLen, `Maximum ${maxLen} characters are allowed`)
  );
};

const onlyValidatedCharOnly = (txt, maxLen = 50) => {
  return yup
    .string()
    .required(txt)
    .matches(
      /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+(?: [^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+)*$/,
      "Only alphabets are allowed"
    )
    .max(maxLen, `Maximum ${maxLen} characters are allowed`);
};

const onlyValidatedMon = (txt, maxLen = 50) => {
  return yup
    .string()
    .required(txt)
    .max(maxLen, `Maximum ${maxLen} characters are allowed`)
    .matches(/[а-яА-ЯЁёӨҮөү0-9]+/, "Only mongolian alphabets are allowed");
};

const onlyValidatedMonCharONly = (txt, maxLen = 50) => {
  return yup
    .string()
    .required(txt)
    .max(maxLen, `Maximum ${maxLen} characters are allowed`)
    .matches(/^(?:[а-яА-ЯЁёӨҮөү]+ ?)+$/, "Only mongolian alphabets are allowed");
};

const commonForString = (txt, field, bool, maxLen = 50) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        // .matches(/[a-zA-Z]+/, "Only alphabets are allowed")
        .max(maxLen, `Maximum ${maxLen} characters are allowed`),
  });
};

const commonForStringCharOnly = (txt, field, bool, maxLen = 50) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .matches(
          /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+(?: [^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+)*$/,
          "Only alphabets are allowed"
        )
        .max(maxLen, `Maximum ${maxLen} characters are allowed`),
  });
};

const commonForStringMon = (txt, field, bool, maxLen = 50) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .matches(/[а-яА-ЯЁёӨҮөү0-9]+/,"Only mongolian alphabets are allowed")
        .max(maxLen, `Maximum ${maxLen} characters are allowed`),
  });
};

const commonForStringMonCharOnly = (txt, field, bool, maxLen = 50) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .matches(
          /^(?:[а-яА-ЯЁёӨҮөү]+ ?)+$/,
          "Only mongolian alphabets are allowed"
        )
        .max(maxLen, `Maximum ${maxLen} characters are allowed`),
  });
};

const commonForNumber = (txt, field, bool, maxLen = 12, type) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .max(maxLen, `Maximum ${maxLen} digits are allowed`)
        .matches(/^[0-9]+$/, "Must be only digits"),
  });
};

export const addBankSchema = yup.object().shape({
  [chooseAccount]: onlyValidated("Bank Account is required"),
  [accountNo]: yup
    .string()
    .matches(/^[0-9]+$/, "Account Number should only contain numbers")
    .max(25, "Maximum 12 characters allowed")
    .required("Account Number is required"),
  // [accountName]: yup
  //   .string()
  //   .trim()
  //   .required("Account Name is required")
  //   .max(25, "Maximum 25 characters allowed")
  //   .matches(/^[a-zA-Zа-яА-ЯӨҮөү]+(?: [a-zA-Zа-яА-ЯӨҮөү]+)*$/, "Only alphabets are allowed")
});

export const retailKycFirstSchema = yup.object().shape({
  [piNationality]: onlyValidated("Nationality is required"),
  [piFirstName]: yup
    .string()
    .trim()
    .required("First Name is required")
    .max(25, "Maximum 25 characters allowed")
    .matches(
      /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+(?: [^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+)*$/,
      "Only alphabets are allowed"
    ),

  [piLastName]: yup
    .string()
    .trim()
    .required("Last Name is required")
    .max(25, "Maximum 25 characters allowed")
    .matches(
      /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+(?: [^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+)*$/,
      "Only alphabets are allowed"
    ),

  [piDob]: yup.date().required("Date of birth is required"),
  [piGender]: onlyValidated("Gender is required"),
  [piIdNumber]: yup
    .string()
    // .required("Civil ID is required")
    .matches(/^[0-9\s]*$/, "Must be only digits")
    .max(12, `Maximum 12 digits are allowed`),
  // [piCivilNumber1]: onlyValidated("Cyrillic letters is required"),
  // [piCivilNumber2]: onlyValidated("Cyrillic letters is required"),

  [piCivilNumber3]: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, `Maximum 8 digits are allowed`)
    .when("nationality", {
      is: localStorage.getItem("nationality") === "Mongolia",
      then: yup.string().required("State registration number is required"),
    }),

  [piCivilNumber]: yup
    .string()
    .required("State registration number is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, `Maximum 8 digits are allowed`),
  // .when('nationality', {
  //   is: localStorage.getItem("nationality") !== "Mongolia",
  //   then: yup.string().required("State registration number is required")
  // })
  [prCountry]: onlyValidatedCharOnly("Country is required"),
  [prProvinceOrCity]: onlyValidatedCharOnly("Province or City is required"),

  [prSumOrDistrict]: onlyValidated("Sum or District is required"),
  [prBagOrKhoroo]: onlyValidated("Bag or Khoroo is required"),
  [prStreet]: onlyValidated("Street is required"),
  // [prRegion]: onlyValidated("Region is required"),
  [prApartment]: onlyValidated("Apartment is required"),
  [prAddressDetails]: onlyValidated("Address details is required"),

  [craCountry]: commonForStringCharOnly(
    "Current address is required",
    isAddressSame,
    false
  ),
  [craProvinceOrCity]: commonForStringCharOnly(
    "Province or City is required",
    isAddressSame,
    false
  ),
  [craSumOrDistrict]: commonForString(
    "Sum or District is required",
    isAddressSame,
    false
  ),
  [craBagOrKhoroo]: commonForString(
    "Bag or Khoroo is required",
    isAddressSame,
    false
  ),
  [craStreet]: commonForString("Street is required", isAddressSame, false),
  // [craRegion]: commonForString("Region is required", isAddressSame, false),
  [craApartment]: commonForString(
    "Apartment is required",
    isAddressSame,
    false
  ),
  [craAddressDetails]: commonForString(
    "Address details is required",
    isAddressSame,
    false
  ),
});

export const retailKycFirstSchemaMon = yup.object().shape({
  [piNationality]: onlyValidated("Nationality is required"),
  [piFirstName]: yup
    .string()
    .trim()
    .required("First Name is required")
    .max(25, "Maximum 25 characters allowed")
    .matches(/^(?:[а-яА-ЯЁёӨҮөү]+ ?)+$/, "Only mongolian alphabets are allowed"),
  [piLastName]: yup
    .string()
    .trim()
    .required("Last Name is required")
    .max(25, "Maximum 25 characters allowed")
    .matches(/^(?:[а-яА-ЯЁёӨҮөү]+ ?)+$/, "Only mongolian alphabets are allowed"),
  [piDob]: yup.date().required("Date of birth is required"),

  [piGender]: onlyValidated("Gender is required"),
  [piIdNumber]: yup
    .string()
    // .required("Civil ID is required")
    .matches(/^[0-9\s]*$/, "Must be only digits")
    .max(12, `Maximum 12 digits are allowed`),
  // [piCivilNumber1]: onlyValidatedMon("Cyrillic letters is required"),
  // [piCivilNumber2]: onlyValidatedMon("Cyrillic letters is required"),
  [piCivilNumber3]: yup
    .string()
    .required("State registration number is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(8, `Maximum 8 digits are allowed`)
    .min(8, `Minimum 8 digits are allowed`),

  [prCountry]: onlyValidatedMonCharONly("Country is required"),
  [prProvinceOrCity]: onlyValidatedMonCharONly("Province or City is required"),

  [prSumOrDistrict]: onlyValidatedMon("Sum or District is required"),
  [prBagOrKhoroo]: onlyValidatedMon("Bag or Khoroo is required"),
  [prStreet]: onlyValidatedMon("Street is required d"),
  // [prRegion]: onlyValidated("Region is required"),
  [prApartment]: onlyValidatedMon("Apartment is required"),
  [prAddressDetails]: onlyValidatedMon("Address details is required"),

  [craCountry]: commonForStringMonCharOnly(
    "Current address is required",
    isAddressSame,
    false
  ),

  [craProvinceOrCity]: commonForStringMonCharOnly(
    "Province or City is required",
    isAddressSame,
    false
  ),
  [craSumOrDistrict]: commonForStringMon(
    "Sum or District is required",
    isAddressSame,
    false
  ),
  [craBagOrKhoroo]: commonForStringMon(
    "Bag or Khoroo is required",
    isAddressSame,
    false
  ),
  [craStreet]: commonForStringMon("Street is required", isAddressSame, false),
  // [craRegion]: commonForString("Region is required", isAddressSame, false),
  [craApartment]: commonForStringMon(
    "Apartment is required",
    isAddressSame,
    false
  ),
  [craAddressDetails]: commonForStringMon(
    "Address details is required",
    isAddressSame,
    false
  ),
});

const {
  isUnemployed,
  empWorkplace,
  empAreasOfActivity,
  empPosition,
  empAddressOfOrganisation,
  empContactNumber,
  isAccountOnBehalf,
  behalfFirstName,
  behalfLastName,
  behalfDob,
  behalfIdNumber,
  behalfIdNumber1,
  behalfIdNumber2,
  behalfIdNumber3,
  behalfCivilNumber,
  behalfContactNumber,
  isPoliticalPerson,
  poliPrevOrganisation,
  poliPrevPosition,
  isPoliticalRelatives,
  poliRelFirstName,
  poliRelLastName,
  poliRelOrganisationName,
  poliRelPosition,
  isPoliticalAssociates,
  poliAssoFirstName,
  poliAssoLastName,
  poliAssoOrganisationName,
  poliAssoPosition,
} = retailFieldNameStep2;

const commonForContact = (txt, field, bool, maxLen = 12) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .matches(/^[0-9]+$/, "Must be only digits")
        .max(12, `Maximum ${maxLen} digits are allowed`),
  });
};

const commonForContactMon = (txt, field, bool, maxLen = 12) => {
  return yup.string().when(field, {
    is: bool,
    then: (schema) =>
      schema
        .required(txt)
        .matches(/[а-яА-ЯӨҮөү]+/, "Only mongolian alphabets are allowed"),
  });
};

export const retailKycSecondSchema = yup.object().shape({
  [empWorkplace]: commonForString(
    "Name of the workplace is required",
    isUnemployed,
    true
  ),
  [empAreasOfActivity]: commonForString(
    "Areas of activity is required",
    isUnemployed,
    true
  ),
  [empPosition]: commonForString("Position is required", isUnemployed, true),
  [empAddressOfOrganisation]: commonForString(
    "Address of the organisation is required",
    isUnemployed,
    true
  ),
  [empContactNumber]: commonForContact(
    "Contact number is required",
    isUnemployed,
    true
  ),

  [behalfFirstName]: commonForStringCharOnly(
    "First name is required",
    isAccountOnBehalf,
    true
  ),
  [behalfLastName]: commonForStringCharOnly(
    "Last name is required",
    isAccountOnBehalf,
    true
  ),
  [behalfDob]: yup.date().when("isAccountOnBehalf", {
    is: (bo) => bo === true,
    then: (schema) => schema.required("Date of birth is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  [behalfIdNumber]: commonForContact(
    "ID number is required",
    isAccountOnBehalf,
    true
  ),

  [behalfContactNumber]: commonForContact(
    "Contact number is required",
    isAccountOnBehalf,
    true
  ),

  [poliPrevOrganisation]: commonForString(
    "Previous organisation is required",
    isPoliticalPerson,
    true
  ),
  [poliPrevPosition]: commonForString(
    "current position is required",
    isPoliticalPerson,
    true
  ),

  [poliRelFirstName]: commonForStringCharOnly(
    "First name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelLastName]: commonForStringCharOnly(
    "Last name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelOrganisationName]: commonForString(
    "Organisation name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelPosition]: commonForString(
    "Position is required",
    isPoliticalRelatives,
    true
  ),

  [poliAssoFirstName]: commonForStringCharOnly(
    "First name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoLastName]: commonForStringCharOnly(
    "Last name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoOrganisationName]: commonForString(
    "Organisation name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoPosition]: commonForString(
    "Position is required",
    isPoliticalAssociates,
    true
  ),
});

export const retailKycSecondSchemaMon = yup.object().shape({
  [empWorkplace]: commonForStringMon(
    "Name of the workplace is required",
    isUnemployed,
    true
  ),
  [empAreasOfActivity]: commonForStringMon(
    "Areas of activity is required",
    isUnemployed,
    true
  ),
  [empPosition]: commonForStringMon("Position is required", isUnemployed, true),
  [empAddressOfOrganisation]: commonForStringMon(
    "Address of the organisation is required",
    isUnemployed,
    true
  ),
  [empContactNumber]: commonForContact(
    "Contact number is required",
    isUnemployed,
    true
  ),

  [behalfFirstName]: commonForStringMonCharOnly(
    "First name is required",
    isAccountOnBehalf,
    true
  ),
  [behalfLastName]: commonForStringMonCharOnly(
    "Last name is required",
    isAccountOnBehalf,
    true
  ),
  [behalfDob]: yup.date().when("isAccountOnBehalf", {
    is: (bo) => bo === true,
    then: (schema) => schema.required("Date of birth is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  [behalfIdNumber3]: commonForContact(
    "ID number is required",
    isAccountOnBehalf,
    true
  ),

  // [behalfIdNumber1]: commonForContactMon(
  //   "Cyrillic letters is required",
  //   isAccountOnBehalf,
  //   true
  // ),

  // [behalfIdNumber2]: commonForContactMon(
  //   "Cyrillic letters is required",
  //   isAccountOnBehalf,
  //   true
  // ),

  [behalfContactNumber]: commonForContact(
    "Contact number is required",
    isAccountOnBehalf,
    true
  ),

  [poliPrevOrganisation]: commonForStringMon(
    "Previous organisation is required",
    isPoliticalPerson,
    true
  ),
  [poliPrevPosition]: commonForStringMon(
    "current position is required",
    isPoliticalPerson,
    true
  ),

  [poliRelFirstName]: commonForStringMonCharOnly(
    "First name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelLastName]: commonForStringMonCharOnly(
    "Last name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelOrganisationName]: commonForStringMon(
    "Organisation name is required",
    isPoliticalRelatives,
    true
  ),
  [poliRelPosition]: commonForStringMon(
    "Position is required",
    isPoliticalRelatives,
    true
  ),

  [poliAssoFirstName]: commonForStringMonCharOnly(
    "First name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoLastName]: commonForStringMonCharOnly(
    "Last name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoOrganisationName]: commonForStringMon(
    "Organisation name is required",
    isPoliticalAssociates,
    true
  ),
  [poliAssoPosition]: commonForStringMon(
    "Position is required",
    isPoliticalAssociates,
    true
  ),
});

const {
  orgNameOfOrganisation,
  orgAreaOfActivity,
  orgStateRegistrationNumber,
  orgIDNumber,
  orgContactNumber,
  isLegalEntity,
  legalOrganisationName,
  legalAreaOfActivity,
  legalStateRegistrationNumber,
  legalRegisterNumber,
  legalContactPhoneNumber,
  isHolderPoli,
  holderPoliFirstName,
  holderPoliLastName,
  holderPoliPosition,
  isHolderPoliRela,
  holderRelaFirstName,
  holderRelaLastName,
  holderRelaPosition,
  isHolderAsso,
  holderAssoFirstName,
  holderAssoLastName,
  holderAssoPosition,
} = corporateFieldNameStep1;

export const corporateKycFirstSchema = yup.object().shape({
  [orgNameOfOrganisation]: onlyValidated("Name of organisation is required"),
  [orgAreaOfActivity]: onlyValidated("Area of activity is required"),
  [orgStateRegistrationNumber]: yup
    .string()
    .required("State registration Id is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(7, `Maximum 7 digits are allowed`),

  // [orgIDNumber]: onlyValidated("ID number is required"),
  [orgContactNumber]: yup
    .string()
    .required("Contact number is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(12, `Maximum 12 digits are allowed`),

  [legalOrganisationName]: commonForString(
    "Organisation name is required",
    isLegalEntity,
    true
  ),
  [legalAreaOfActivity]: commonForString(
    "Area of activity is required",
    isLegalEntity,
    true
  ),
  [legalStateRegistrationNumber]: commonForNumber(
    "State registration number is required",
    isLegalEntity,
    true,
    50
  ),
  [legalRegisterNumber]: commonForNumber(
    "Register number is required",
    isLegalEntity,
    true,
    7
  ),

  [legalContactPhoneNumber]: commonForContact(
    "Contact number is required",
    isLegalEntity,
    true
  ),

  [holderPoliFirstName]: commonForStringCharOnly(
    "First name is required",
    isHolderPoli,
    true
  ),
  [holderPoliLastName]: commonForStringCharOnly(
    "Last name is required",
    isHolderPoli,
    true
  ),
  [holderPoliPosition]: commonForString(
    "Position is required",
    isHolderPoli,
    true
  ),

  [holderRelaFirstName]: commonForStringCharOnly(
    "First name is required",
    isHolderPoliRela,
    true
  ),
  [holderRelaLastName]: commonForStringCharOnly(
    "Last name is required",
    isHolderPoliRela,
    true
  ),
  [holderRelaPosition]: commonForString(
    "Position is required",
    isHolderPoliRela,
    true
  ),

  [holderAssoFirstName]: commonForStringCharOnly(
    "First name is required",
    isHolderAsso,
    true
  ),
  [holderAssoLastName]: commonForStringCharOnly(
    "Last name is required",
    isHolderAsso,
    true
  ),
  [holderAssoPosition]: commonForString(
    "Position is required",
    isHolderAsso,
    true
  ),
});

const {
  praCountry,
  praProvince,
  praDistrict,
  praKhorro,
  praApartment,
  praDoor,
} = corporateFieldNameStep2;

export const corporateKycSecondSchema = yup.object().shape({
  [praCountry]: onlyValidated("Country is required"),
  [praProvince]: onlyValidated("Province, City is required"),
  [praDistrict]: onlyValidated("District is required"),
  [praKhorro]: onlyValidated("Khoroo is required"),
  [praApartment]: onlyValidated("Apartment is required"),
  [praDoor]: onlyValidated("Door is required"),
});
